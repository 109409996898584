import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose otc mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center mb-2">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">What Makes Coinsclone</span> The</span>
              Top NFT Marketplace Development Company?</h3>
              <p className='pharagraph sub-head'>
              Coinsclone is an exemplary place for developing an NFT Marketplace with agile features and functionalities. We have pioneer expertise and experience in creating an NFT Marketplace for various clients worldwide. Our developer team is ready to customize your NFT Marketplaces with anti-phishing protocols, database encryption, and threat protection. As we have delivered nearly 250+ blockchain projects successfully, we have a strong reputation in NFT Marketplace Development services. Our developer team uses a cutting-edge technology stack to create a seamless user dashboard, tamper-proof smart contracts, and cybersecurity for storing crypto assets. So, to ensure your success in the crypto space, contact our developer team and generate substantial revenue.
              </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock" >
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nft/world-class-experience.svg" alt="World-class experience" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  World-class experience
                </h3>
                <p className="pharagraph">
                  When you work with our developers for your NFT Marketplace development you get unparalleled experience because we have worked on multiple marketplace development projects.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock" >
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nft/customizable-code.svg" alt="Hardcore Blockchain Developers" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Customizable code
                </h3>
                <p className="pharagraph">
                  Our code will be easy to customize. Once we have deployed your marketplace on the server, you can always modify it through us, or by yourself.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock" >
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nft/full-support.svg" alt="Full support" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Full support
                </h3>
                <p className="pharagraph">
                  From programming to design to deployment and ongoing support, you get everything under the same roof.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" > 
            <div className="innerBlock" >
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nft/auctions-and-trading.svg" alt="Auctions and trading" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Auctions and trading
                </h3>
                <p className="pharagraph">
                  Your NFT Marketplace users will be able to use multiple forms of transactions in tokens, including auctions and direct trading.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock" >
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nft/predefined-costing.svg" alt="Predefined costing" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Predefined costing
                </h3>
                <p className="pharagraph">
                We don’t believe in catching you by surprise. All our estimates and costs are already predefined by our experts.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock" >
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nft/scalable-solutions.svg" alt="Scalable solutions" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Scalable solutions
                </h3>
                <p className="pharagraph">
                Whether you start from the base or you are a multi-national company, we can easily scale our services according to your budget.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='banner-btn mt-4 text-center'>
        <ButtonComponent />
        </div>
      </div>
    </section>
  )
}

export default WhyChoose