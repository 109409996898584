import React from 'react'




const HowOur = () => {



  return (
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>How Does</span> our
          </span>
            NFT Marketplace Software Work?
          </h2>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="998.194" height="1242.787" viewBox="0 0 998.194 1242.787">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_8849" data-name="Rectangle 8849" width="113.614" height="289.356" fill="none" />
            </clipPath>
            <clipPath id="clip-path-3">
              <rect id="Rectangle_8835" data-name="Rectangle 8835" width="10.222" height="11.54" transform="translate(51.791 27.693)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-4">
              <rect id="Rectangle_8836" data-name="Rectangle 8836" width="38.057" height="51.857" transform="translate(66.12 44.571)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-5">
              <rect id="Rectangle_8837" data-name="Rectangle 8837" width="7.194" height="32.392" transform="translate(36.124 48.926)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-6">
              <rect id="Rectangle_8838" data-name="Rectangle 8838" width="53.946" height="61.26" transform="translate(27.645 72.782)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-7">
              <rect id="Rectangle_8839" data-name="Rectangle 8839" width="4.128" height="6.293" transform="translate(43.832 19.327)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-8">
              <rect id="Rectangle_8840" data-name="Rectangle 8840" width="63.009" height="35.959" transform="translate(47.722 67.563)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-9">
              <rect id="Rectangle_8841" data-name="Rectangle 8841" width="62.95" height="35.902" transform="translate(47.827 94.716)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-10">
              <rect id="Rectangle_8842" data-name="Rectangle 8842" width="48.725" height="48.726" transform="translate(54.886 74.726)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-11">
              <rect id="Rectangle_8843" data-name="Rectangle 8843" width="46.772" height="35.75" transform="translate(56.841 87.702)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-12">
              <rect id="Rectangle_8844" data-name="Rectangle 8844" width="47.337" height="34.059" transform="translate(54.886 74.726)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-13">
              <rect id="Rectangle_8845" data-name="Rectangle 8845" width="36.409" height="24.242" transform="translate(60.859 88.555)" fill="none" />
            </clipPath>
            <clipPath id="clip-path-15">
              <rect id="Rectangle_8847" data-name="Rectangle 8847" width="38.237" height="15.72" transform="translate(60.984 91.587)" fill="none" />
            </clipPath>
          </defs>
          <g id="Flow" transform="translate(-427 -4990.213)">
            <g id="Group_28359" data-name="Group 28359" transform="translate(-107.399 147)">
              <line id="Line_224" data-name="Line 224" x2={144} transform="translate(1093.316 5297.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <line id="Line_227" data-name="Line 227" x2="217.399" transform="translate(875.917 5440.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <line id="Line_225" data-name="Line 225" y1={143} transform="translate(1092.917 5297.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <line id="Line_226" data-name="Line 226" x2={111} transform="translate(1154.316 5512.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <line id="Line_230" data-name="Line 230" y2={334} transform="translate(1154.316 5512.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <line id="Line_231" data-name="Line 231" y2={182} transform="translate(833.316 5701.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
              <line id="Line_232" data-name="Line 232" x1={151} transform="translate(833.316 5883.329)" fill="none" stroke="#707070" strokeWidth={2} strokeDasharray={5} opacity="0.3" />
            </g>
            <g id="Line">
              <line id="Line_204" data-name="Line 204" y2={199} transform="translate(646.793 5210.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_205" data-name="Line 205" y2={73} transform="translate(646.793 5482.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_207" data-name="Line 207" y2={353} transform="translate(481.056 5505.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_229" data-name="Line 229" x1={125} transform="translate(475.917 5858.329)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_220" data-name="Line 220" y1={131} transform="translate(1163.5 5279.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_221" data-name="Line 221" y1={126} transform="translate(1265.5 5501.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_222" data-name="Line 222" y1={161} transform="translate(1265.5 5714.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_223" data-name="Line 223" y1={83} transform="translate(975.5 6089.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
            </g>
            <g id="Dots">
              <circle id="Ellipse_134" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(640 5203)" fill="#4b9aff" />
              <circle id="Ellipse_1376" data-name="Ellipse 1376" cx="7.5" cy="7.5" r="7.5" transform="translate(640 5294)" fill="#4b9aff" />
              <circle id="Ellipse_1369" data-name="Ellipse 1369" cx="7.5" cy="7.5" r="7.5" transform="translate(473 5494.095)" fill="#4b9aff" />
              <circle id="Ellipse_1373" data-name="Ellipse 1373" cx="7.5" cy="7.5" r="7.5" transform="translate(1156 5272.095)" fill="#ff6464" />
              <circle id="Ellipse_1374" data-name="Ellipse 1374" cx="7.5" cy="7.5" r="7.5" transform="translate(1258 5494.095)" fill="#ff6464" />
              <circle id="Ellipse_1375" data-name="Ellipse 1375" cx="7.5" cy="7.5" r="7.5" transform="translate(968 6079.095)" fill="#ff6464" />
            </g>
            <g id="Text">
              <g id="Ellipse_196" data-name="Ellipse 196" transform="translate(1187 5114.096)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <circle cx={108} cy={108} r={108} stroke="none" />
                <circle cx={108} cy={108} r={107} fill="none" />
              </g>
              <g id="Ellipse_1377" data-name="Ellipse 1377" transform="translate(451 4999.596)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <circle cx={108} cy={108} r={108} stroke="none" />
                <circle cx={108} cy={108} r={107} fill="none" />
              </g>
              <g id="Admin" transform="translate(-221.776 -6496.716)">
                <rect id="Rectangle_298" data-name="Rectangle 298" width={179} height={43} rx="21.5" transform="translate(1307.776 11718.812)" />
                <text id="Admin_Dashboard" data-name="Admin Dashboard" transform="translate(1397.776 11744.812)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-61.565" y={0}>Admin Dashboard</tspan></text>
              </g>
              <g id="Register" transform="translate(-346 967.838)">
                <g id="User" transform="translate(-380.685 -7537.5)">
                  <rect id="Rectangle_298-2" data-name="Rectangle 298" width={141} height={43} rx="21.5" transform="translate(1330 11719)" />
                  <text id="User-2" data-name="User" transform="translate(1401 11745)" fill="#fff" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-15.414" y={0}>User</tspan></text>
                </g>
              </g>
              <text id="Enter_Market_Place" data-name="Enter Marketplace" transform="translate(672 5298.526)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700} letterSpacing="0.03em"><tspan x={0} y={0}>Enter Marketplace</tspan></text>
              <g id="_2fa" data-name="2fa" transform="translate(26 -93)">
                <g id="Rectangle_45" data-name="Rectangle 45" transform="translate(401 5503.829)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="352.389" height={70} rx={25} stroke="none" />
                  <rect x={1} y={1} width="350.389" height={68} rx={24} fill="none" />
                </g>
                <text id="Connect_your_Web3_Wallet" data-name="Connect your Web3 Wallet" transform="translate(577 5543.829)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x="-106.888" y={0}>Connect your </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Web3 Wallet</tspan></text>
              </g>
              <g id="_2fa-2" data-name="2fa">
                <g id="Rectangle_8830" data-name="Rectangle 8830" transform="translate(1134 5410.829)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width="256.389" height={70} rx={25} stroke="none" />
                  <rect x={1} y={1} width="254.389" height={68} rx={24} fill="none" />
                </g>
                <text id="Manage_Listing" data-name="Manage Listing" transform="translate(1262 5450.829)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x="-61.2" y={0}>Manage </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Listing</tspan></text>
              </g>
              <g id="KYC" transform="translate(-96 4773.17)">
                <g id="Group_28360" data-name="Group 28360" transform="translate(43)">
                  <g id="Rectangle_45-2" data-name="Rectangle 45" transform="translate(571.417 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                    <rect width={255} height={62} rx={25} stroke="none" />
                    <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
                  </g>
                  <text id="Mint_your_own_NFT" data-name="Mint your own NFT" transform="translate(698.417 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-61.551" y={0}>Mint your own </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>NFT</tspan></text>
                </g>
              </g>
              <g id="My_Profile" data-name="My Profile" transform="translate(32.194 4925.194)">
                <g id="Rectangle_45-3" data-name="Rectangle 45" transform="translate(571.223 874.635)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={255} height={105} rx={25} stroke="none" />
                  <rect x={1} y={1} width={253} height={103} rx={24} fill="none" />
                </g>
                <text id="Upload_Deposit_NFT_to_the_market_place" data-name="Upload / Deposit NFT to 
the marketplace" transform="translate(698.223 920.635)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-83.125" y={0}>Upload / Deposit NFT </tspan><tspan y={0} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}>to </tspan><tspan fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-53.837" y={21}>the marketplace</tspan></tspan></text>
              </g>
              <g id="User_Balance" data-name="User Balance" transform="translate(563.694 4842.38)">
                <g id="Rectangle_45-4" data-name="Rectangle 45" transform="translate(593.5 784.524)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={211} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={209} height={60} rx={24} fill="none" />
                </g>
                <text id="Check_Auctions" data-name="Check Auctions" transform="translate(699 820.524)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-51.59" y={0}>Check </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Auctions</tspan></text>
              </g>
              <g id="User_Balance-2" data-name="User Balance" transform="translate(563.694 5059.38)">
                <g id="Rectangle_45-5" data-name="Rectangle 45" transform="translate(536.5 784.524)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={325} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={323} height={60} rx={24} fill="none" />
                </g>
                <text id="Admin_Profit_Management" data-name="Admin Profit Management" transform="translate(699 820.524)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-88.781" y={0}>Admin </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Profit Management</tspan></text>
              </g>
              <g id="Admin_Disputes" data-name="Admin Disputes" transform="translate(569 5147.095)">
                <circle id="Ellipse_134-2" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(689 561)" fill="#ff6464" />
              </g>
              <g id="Website_Content" data-name="Website Content" transform="translate(559 5099.095)">
                <g id="Rectangle_45-6" data-name="Rectangle 45" transform="translate(314.417 894.734)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={200} height={65} rx={20} stroke="none" />
                  <rect x={1} y={1} width={198} height={63} rx={19} fill="none" />
                </g>
                <g id="Group_27450" data-name="Group 27450" transform="translate(5 2)">
                  <text id="Buy_Sell_NFT" data-name="Buy / Sell NFT" transform="translate(409.417 929.734)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-46.921" y={0}>Buy / Sell</tspan><tspan y={0} xmlSpace="preserve" fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}> NFT</tspan></text>
                </g>
              </g>
              <g id="Complete_Trade" data-name="Complete Trade" transform="translate(564 5296.429)">
                <g id="Rectangle_45-7" data-name="Rectangle 45" transform="translate(256.5 874.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                  <rect width={305} height={62} rx={25} stroke="none" />
                  <rect x={1} y={1} width={303} height={60} rx={24} fill="none" />
                </g>
                <text id="Manage_Admin_Revenue_System" data-name="Manage Admin Revenue System" transform="translate(409 910.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-108.451" y={0}>Manage </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Admin Revenue System</tspan></text>
              </g>
              <g id="User-3" data-name="User" transform="translate(520.181 4990.213)">
                <path id="Path_1" data-name="Path 1" d="M192.99,297.718a48.972,48.972,0,0,0,13.145,15.771h31.159s-48.622-23.784-44.3-15.771" transform="translate(-99.701 -153.168)" fill="#233862" />
                <path id="Path_2" data-name="Path 2" d="M211.151,438.519a2.875,2.875,0,0,1,.429,3.088.026.026,0,0,1-.008.015c-.8,2.029-3.31,4.25-6.218,6.458-.372.283-.7.542-.991.781-.076.063-.152.125-.223.189s-.115.1-.171.147c-2.721,2.374-1.737,2.962-6.64,7.8-5.591,5.509-9.336,5.509-10.386,4.964-.889-.464,1.023-3.414,3.143-6.731.1-.16.2-.322.309-.484.282-.441.563-.889.843-1.332,2.487-3.993,1.929-5.914,1.53-11.968-.324-4.92-6.888-27.625-6.888-27.625l10.466-1.511s2.649,18.009,6.031,20.864c1.808,1.521,3.877,1.6,5.623,2.736q.185.119.363.243a13.694,13.694,0,0,1,2.788,2.368" transform="translate(-96.163 -213.304)" fill="#ffb27d" />
                <path id="Path_3" data-name="Path 3" d="M212.051,463.815a2.875,2.875,0,0,1,.429,3.088.026.026,0,0,1-.008.015c-.964,2.022-3.414,4.234-6.227,6.437-.367.289-.692.554-.981.8-.076.063-.152.125-.223.189s-.115.1-.171.147c-2.721,2.374-1.737,2.962-6.64,7.8-5.591,5.509-9.336,5.509-10.386,4.964-.889-.464,1.023-3.414,3.143-6.731a23.166,23.166,0,0,0,9.885-6.876c3.387-4.088,5.717-9.4,8.03-12.443q.185.119.363.243a13.694,13.694,0,0,1,2.788,2.368" transform="translate(-97.063 -238.6)" fill="#233862" />
                <path id="Path_4" data-name="Path 4" d="M31.163,424.278c-.731,3.2-5.3,2.537-10.387,1.146s-3.91.13-11.177-.875S.2,420.765.006,419.676c-.173-.967,3.438-1.208,7.313-1.547.487-.042.977-.088,1.466-.135,4.377-.429,7.647-3.156,11.671-5.359,3.812-2.087,9.655-10.854,9.655-10.854l9.974,1.064s-5.463,7.824-7.585,11.232c-1.249,2-1.259,4.7-1.167,6.785a12.831,12.831,0,0,1-.17,3.417" transform="translate(0 -207.857)" fill="#ffb27d" />
                <path id="Path_5" data-name="Path 5" d="M20.776,442.947c-5.087-1.391-3.91.13-11.177-.875S.2,438.288.007,437.2c-.173-.967,3.437-1.208,7.313-1.547l.034.075a20.555,20.555,0,0,0,10.35,3.529c4.941.255,10.217-1.019,13.631-.871a12.831,12.831,0,0,1-.17,3.417c-.731,3.2-5.3,2.537-10.387,1.146" transform="translate(0 -225.38)" fill="#233862" />
                <path id="Path_6" data-name="Path 6" d="M147.536,301.357l8.659,25.4,11.3,33.162a19.777,19.777,0,0,0,6.5-.416,28.1,28.1,0,0,0,6.351-2.88s-2.427-35.343-8.195-55.749c-1.477-5.238-3.178-9.489-5.116-11.908-9.48-11.844-19.5,12.393-19.5,12.393" transform="translate(-76.327 -147.807)" fill="#233862" />
                <path id="Path_7" data-name="Path 7" d="M107.71,232.972c6.175-1.808,23.566,1.2,41.011,5.114l3.837-5.957,37.964-1.926s11.745,28.062,4.421,45.54c-1.077,2.569-2.978,3.71-6.387,3.672h-.676s-22.555.2-29.877,0c-18.84-.514-39.53-14.409-42.236-15.028-4.429-1.012-6.453,7.846-11.161,17.076s-42.988,40.274-42.988,40.274-5.75-.1-8.1-1.055a8,8,0,0,1-3.428-2.3s43.7-81.337,57.622-85.414" transform="translate(-25.913 -119.094)" fill="#2b478b" />
                <path id="Path_8" data-name="Path 8" d="M255.734,60.871c13.288,7.8,40.234,23.939,40.38,23.843,0,0,9.955,4.064,15.765.43,9.683-6.057,15.247-22.918-1.748-38.021-8.476-7.532-14.591-9.477-15.21-21.153-.336-6.334-8.407-27.429-22.815-25.891a21.931,21.931,0,0,1-3.625.139A13.434,13.434,0,0,0,261.145,1.9c-8.257,4.616-25.557,55.544-5.412,58.97" transform="translate(-127.444 0)" fill="#233862" />
                <path id="Path_9" data-name="Path 9" d="M288.2,91.294c-.061.037,6.574,2.5,11.105.644,4.263-1.747,7.643-8.591,7.581-8.6-1.914-.141-3.972-1.873-5.293-12.911l-.824.27-13.855,4.563s.874,4.43,1.4,8.639c.445,3.534.644,6.913-.118,7.39" transform="translate(-148.435 -36.438)" fill="#ffb27d" />
                <path id="Path_10" data-name="Path 10" d="M286.92,75.556s.874,4.429,1.4,8.638c7.919-1.2,11.226-8.971,12.452-13.2Z" transform="translate(-148.436 -36.728)" fill="#ed975d" />
                <path id="Path_11" data-name="Path 11" d="M272.764,54.175s18.84-2.008,17.409-13.722.037-19.861-12.053-19.075-13.876,5.542-14.349,9.568,4.055,23.449,8.993,23.228" transform="translate(-136.442 -11.033)" fill="#ffb27d" />
                <path id="Path_12" data-name="Path 12" d="M265.585,23.4s11.734,10.683,19.886,11.741,11.316-1.482,11.316-1.482a31.931,31.931,0,0,1-7.432-10.666,5.747,5.747,0,0,0-4.646-3.517c-5.823-.663-16.881-1.194-19.124,3.924" transform="translate(-137.398 -9.859)" fill="#233862" />
                <path id="Path_13" data-name="Path 13" d="M270.635,24.015s-4.126,3.777-5.47,6.626c-1.3,2.749-1.845,9.393-.944,12.062,0,0-5.932-12.349-2.225-18.17,3.931-6.172,8.639-.518,8.639-.518" transform="translate(-134.911 -11.2)" fill="#233862" />
                <path id="Path_14" data-name="Path 14" d="M255.012,131.84c-.468,38.013-.663,30.062,3.4,33.781.978.9,3.78,1.489,7.478,1.836,11.631,1.091,32.165-.271,33-2.4,2.891-7.377,1.057-9.744,3.609-21.089.267-1.207.592-2.512.971-3.933,2.083-7.783,3.975-10.4-2.315-23.166-5.7-11.564-11.7-20.988-12.272-20.67-16.126,8.988-17.189,1.035-17.189,1.035S255.239,113.3,255.012,131.84" transform="translate(-131.803 -49.762)" fill="#fff" />
                <path id="Path_15" data-name="Path 15" d="M165.476,343.013l11.3,33.162a19.773,19.773,0,0,0,6.5-.416,28.1,28.1,0,0,0,6.351-2.88s-2.427-35.343-8.195-55.75Z" transform="translate(-85.608 -164.064)" fill="#2b478b" />
                <path id="Path_16" data-name="Path 16" d="M254.42,98.344s-16.4,2.324-18.513,9.3-14.519,48-14.519,48,8.261,1.717,12.2-2.013S247.9,118.419,247.9,118.419Z" transform="translate(-114.533 -50.877)" fill="#fff" />
                <path id="Path_17" data-name="Path 17" d="M277.8,201.541c11.631,1.091,32.165-.271,33-2.4,2.891-7.377,1.056-9.743,3.609-21.089l-5.189-15.978s.449,17.647-4.874,22.141-21.521,8.368-24.153,12.172a14.182,14.182,0,0,0-2.4,5.158" transform="translate(-143.717 -83.846)" fill="#ebf3ff" />
                <path id="Path_18" data-name="Path 18" d="M283.926,226.99s-11.425-2.872-13.308-1.9-6.517,4.561-7.119,5.838,4.156-.869,5.695-1.237c1-.238,3.576.738,4.854,1.2,5.376,1.956,8.725,1.117,8.725,1.117Z" transform="translate(-136.292 -116.344)" fill="#ffb27d" />
                <path id="Path_19" data-name="Path 19" d="M313.246,96.188s15.409,1.371,20.549,8.969c7.236,10.7,20.938,55.809,12.225,61.539-9.813,6.452-43.876.563-43.876.563s-.993-5.351.66-7.88,27.5-5.985,28.718-7.141-14.214-23.562-14.117-27.125-4.159-28.925-4.159-28.925" transform="translate(-156.159 -49.762)" fill="#fff" />
                <path id="Path_20" data-name="Path 20" d="M272.04,231.9s-2.493,3.041-3.293,3.391a2.357,2.357,0,0,1-1.308.243l1.7-2.877Z" transform="translate(-138.358 -119.971)" fill="#ffb27d" />
                <path id="Path_21" data-name="Path 21" d="M270.7,225.3l-7.1,3.21a1.248,1.248,0,0,0,1.148.48,16.706,16.706,0,0,0,3.06-.752Z" transform="translate(-136.374 -116.558)" fill="#ffb27d" />
                <path id="Path_22" data-name="Path 22" d="M193.857,202.267l-55.392-1.509-15.534-41.89,55.307,1.507Z" transform="translate(-63.597 -82.188)" fill="#f99746" />
                <path id="Path_23" data-name="Path 23" d="M153.861,242.277l73.146,1.992-.044,1.615-72.5-1.975Z" transform="translate(-79.599 -125.34)" fill="#ed7d2b" />
                <path id="Path_24" data-name="Path 24" d="M185.161,200.749a1.92,1.92,0,0,1,1.953-2.586,3.781,3.781,0,0,1,3.322,2.73,1.92,1.92,0,0,1-1.953,2.586,3.78,3.78,0,0,1-3.322-2.73" transform="translate(-95.746 -102.518)" fill="#fff" />
              </g>
              <g id="Admin-2" data-name="Admin" transform="translate(1262.194 5110.096)">
                <path id="Path_25" data-name="Path 25" d="M147.507,256.62s6.8,3.868,8.7,3.867,9.377-1.445,11.46-.38,5.854,5.347,6.562,6.746c1.272,2.516-5.121,1.4-8.144-.738a1.906,1.906,0,0,0-1.51-.312c-1.565.341-4.817.932-6.387.351-2.112-.782-13.233-1.649-13.233-1.649Z" transform="translate(-88.049 -155.871)" fill="#ea9963" />
                <path id="Path_26" data-name="Path 26" d="M19.652,111.639s7.05,50.108,18.724,54.6c5.761,2.215,31.218,7.235,38.749,7.739,0,0,1.844-1.5,1.395-7.588,0,0-26.671-9.792-30.461-15.117-5.639-7.925,3.5-32.377-3.575-39.821s-18.994-10.643-24.831.191" transform="translate(-11.937 -63.561)" fill="#1f9feb" />
                <path id="Path_27" data-name="Path 27" d="M11.777,345.839a87.829,87.829,0,0,0,6.887,9.854c7.578,9.566,15.826,17.453,17.263,24.909a33.7,33.7,0,0,0,1.332,4.772c.6,1.708,1.342,3.5,2.2,5.36a165.086,165.086,0,0,0,12.825,21.925c8.3,12.3,16.1,22.56,16.1,22.56l14.312-.61c-.573-1.434-6.815-18.415-15.166-39.166-3.391-8.424-7-17.385-10.476-26.026q-.7-1.731-1.386-3.442c-1.064-2.645-2.11-5.248-3.133-7.783-7.841-19.485-14.086-35-14.086-35S7.112,311.318,3.858,319.011c-2.683,6.343-.5,12.557,7.919,26.828" transform="translate(-1.686 -192.191)" fill="#233862" />
                <path id="Path_28" data-name="Path 28" d="M171.032,606.51s.732,11.81,3.017,11.98c.3.022,11.589.022,11.589.022l14.406-.084s-.094-3.471-4.305-4.2-11.259-3.694-13.81-9.259c-2.684-5.855-10.9,1.545-10.9,1.545" transform="translate(-103.885 -366.086)" fill="#163560" />
                <path id="Path_29" data-name="Path 29" d="M25.966,517.778s-10.7,5.063-10,7.248c.092.29,4.28,10.77,4.28,10.77l5.424,13.346s3.188-1.376,2.307-5.558-.749-11.826,3.473-16.26c4.441-4.665-5.479-9.545-5.479-9.545" transform="translate(-9.675 -314.498)" fill="#163560" />
                <path id="Path_30" data-name="Path 30" d="M21.7,409.562s-6.008-6.074-7.1-10.1c0,0,20.664-20.082,28.05-24.958,8.477-5.6,13.984-5.627,12.623-7-4.457-4.491-41.909-17.6-48.005-27.08a29.4,29.4,0,0,1-3.939-9.081,21.467,21.467,0,0,1-.289-9.785l46.584-1.348s34.639,35.928,34.439,49.45c-.216,14.617-62.358,39.9-62.358,39.9" transform="translate(-1.593 -194.496)" fill="#2b478b" />
                <path id="Path_31" data-name="Path 31" d="M51.106,80.779c-9.425,2.436-25.047-6.295-25.047-6.295a23.13,23.13,0,0,0,8.315-13.033c.115-.451.216-.914.309-1.389l4.84-1.026,7.2,6.449a61.936,61.936,0,0,1-1.065,7.28q-.233,1.2-.542,2.583a22.2,22.2,0,0,1,5.993,5.431" transform="translate(-15.828 -35.858)" fill="#ffb27d" />
                <path id="Path_32" data-name="Path 32" d="M.253,137.971c1.35,18.155-.426,45.767,1.106,50.549,1.322,4.126,16.38,1.673,22.39.3,2.224-.51,4.919-1.238,7.7-2.1,7.763-2.4,16.221-5.836,17.129-8.384a2.191,2.191,0,0,0,.084-.293,2.333,2.333,0,0,0-.016-1.068c-.3-1.3-1.469-2.7-1.606-5.92-.154-3.591-.13-7.819-.265-12.366-.007-.512-.014-1.071-.07-1.58l0-.186c0-.1,0-.194-.007-.291-.13-5.07-.4-10.4-.7-15.689-.2-3.321-.421-6.609-.682-9.759-.677-8.133-1.589-15.365-2.8-19.937-1.974-7.454-6.325-12.315-10.286-15.877l-2.865-2.457a16.565,16.565,0,0,1-10.395-1.385c-3.7-1.757-4.977-3.016-5.622-2.541C4.8,95.575-1.341,115.776.253,137.971" transform="translate(0 -53.987)" fill="#46b7f9" />
                <path id="Path_33" data-name="Path 33" d="M45.933,229.67c7.763-2.4,16.221-5.836,17.129-8.384a2.178,2.178,0,0,0,.084-.294c-.005-.356-.01-.712-.016-1.067-.3-1.3-1.468-2.7-1.606-5.92-.154-3.591-.13-7.819-.265-12.366-.007-.512-.014-1.071-.07-1.58l0-.186c0-.1,0-.194-.007-.291L29.8,159.6S8.7,203.528,45.933,229.67" transform="translate(-14.483 -96.94)" fill="#1f9feb" />
                <path id="Path_34" data-name="Path 34" d="M59.583,65.485a61.936,61.936,0,0,1-1.065,7.28C51.489,70.493,48.3,64.144,47.237,61.451c.115-.451.216-.914.309-1.389l4.84-1.026Z" transform="translate(-28.692 -35.858)" fill="#ea9963" />
                <path id="Path_35" data-name="Path 35" d="M59.856,43.627s-15.329-2.558-13.589-12.08S47.2,15.283,57.062,16.514s11.093,5.211,11.285,8.53-4.458,19-8.49,18.583" transform="translate(-28.019 -9.942)" fill="#ffb27d" />
                <rect id="Rectangle_8831" data-name="Rectangle 8831" width="37.218" height="1.421" transform="translate(92.175 115.153) rotate(-71.995)" fill="#4a75cb" />
                <rect id="Rectangle_8832" data-name="Rectangle 8832" width="2.337" height="39.056" transform="matrix(0.021, -1, 1, 0.021, 54.481, 114.759)" fill="#4a75cb" />
                <path id="Path_36" data-name="Path 36" d="M140.76,286.068s12.546,3.308,17.251,2.961,8.618-2.585,9.21-3.753,1.835-3.24-1.367-2.858-7.576,1.064-7.712.448,2.612-.557,2.612-.557.83-1.207-.215-1.251-4.1-.871-5.227-.3-2.179,2.658-3.006,2.57-10.5-4.357-10.5-4.357Z" transform="translate(-85.497 -169.444)" fill="#ffb27d" />
                <path id="Path_37" data-name="Path 37" d="M27.576,118.672S29.059,169.253,40.167,175c5.481,2.836,30.23,10.632,37.659,11.963,0,0,2-1.284,2.223-7.388,0,0-25.43-12.673-28.609-18.384-4.731-8.5,7.049-31.794.837-39.973s-17.706-12.672-24.7-2.548" transform="translate(-16.75 -68.538)" fill="#46b7f9" />
                <path id="Path_38" data-name="Path 38" d="M42.237,25.593s-.176-3.267,1.84-5.139c0,0-.7-3.275.594-3.815s1.566,2.214,2.16,1.782A13.23,13.23,0,0,0,51.2,12.914c1.133-3.023,11.51.638,12.719,1.183s5.1-1.453,5.853-3.072A22.765,22.765,0,0,1,66.644,8C65.673,6.7,58.87-2.042,49.367.442S38.57,7.46,39.109,11.348s-.764,12.94,3.127,14.245" transform="translate(-23.692 0)" fill="#2b478b" />
              </g>
            </g>
            <g id="Group_28402" data-name="Group 28402" transform="translate(513.417 5666.829)">
              <g id="Group_28401" data-name="Group 28401" clipPath="url(#clip-path)">
                <g id="Group_28395" data-name="Group 28395">
                  <g id="Group_28394" data-name="Group 28394" clipPath="url(#clip-path)">
                    <path id="Path_39" data-name="Path 39" d="M73.7,27.693c1.23,5.014-2.586,7.564-.687,12.014a11.315,11.315,0,0,0,3.251,3.824,23.059,23.059,0,0,1,3.795,2.725,5.97,5.97,0,0,1,1.986,4.1c.011,2.413-2.081,4.452-4.41,5.084a12.872,12.872,0,0,1-7.117-.578,37.3,37.3,0,0,1-5.258-2.086A16.976,16.976,0,0,1,58.2,47.125a13.262,13.262,0,0,1-1.966-7.216,22.8,22.8,0,0,1,1.414-7.415,20.872,20.872,0,0,1,2.436-5.031,18.272,18.272,0,0,1,3.366-3.606c1.224-1.019,6-10.514,5.993-10.252-.306,9.268,2.743,7.932,4.253,14.088" fill="#143556" />
                    <path id="Path_40" data-name="Path 40" d="M41.945,18.346a10.88,10.88,0,0,1-.846,5.011C40.107,25.193,38.1,26.225,36.165,27s-4.008,1.434-5.508,2.886a6.744,6.744,0,0,0-1.878,3.349c-.509,2.273.166,4.781-.809,6.9-.643,1.4-1.752,2.522-2.377,3.926-4.154,9.337,1.013,12.724,2.413,13.324,3.266,1.4,9.3-4.54,12.843-4.8,4.23-.317,8.267-1.831,12.237-3.329a22.493,22.493,0,0,0,4.873-2.3,11.279,11.279,0,0,0,2.7-2.61,6.1,6.1,0,0,0,1.009-1.928c.558-1.976-.381-4.033-1.28-5.879q-5.6-11.5-10.362-23.39c-.683-1.7-1.352-3.417-1.867-5.18-.323-1.105,2.83-5.886,1.231-5.661-2.53.358-6.027,4.173-7.164,9.034-.413,1.768-.242,5.2-.279,7.015" fill="#143556" />
                    <rect id="Rectangle_8833" data-name="Rectangle 8833" width="11.081" height="25.121" transform="matrix(0.999, -0.033, 0.033, 0.999, 70.112, 253.269)" fill="#fcb5a7" />
                    <path id="Path_41" data-name="Path 41" d="M81.765,276.2a40.688,40.688,0,0,0,7.487,4.053c5.276,2.078,9.762,1.089,11.58,3.8a3.191,3.191,0,0,1-.893,4.846s-29.575-.082-30.332-.658.928-11.84.928-11.84a10.847,10.847,0,0,0,11.23-.2" fill="#f77979" />
                    <rect id="Rectangle_8834" data-name="Rectangle 8834" width="24.242" height="10.693" transform="matrix(0.373, -0.928, 0.928, 0.373, 4.348, 272.952)" fill="#fcb5a7" />
                    <path id="Path_42" data-name="Path 42" d="M15.236,274.834a20.628,20.628,0,0,0,6.749,6.2c3.009,1.7,5.9.1,7.553,2.949,2.124,3.658-1.875,5.128-1.875,5.128A32.814,32.814,0,0,1,.05,279.265c-.647-.652,5.116-8.594,5.116-8.594a10.57,10.57,0,0,0,10.07,4.163" fill="#f77979" />
                    <path id="Path_43" data-name="Path 43" d="M79.6,122.814s12.709,65.146,12.328,81.809S85.109,268.7,85.109,268.7c-6.035,2.637-7.823,2.748-15.949,1.173l-1.877-65.439c-7.309-22.744-18.845-65.511-17.527-81.061Z" fill="#143556" />
                    <path id="Path_44" data-name="Path 44" d="M63.518,128.681c0,16.245-4.039,58.751-7.488,72.829s-33.075,66.019-33.075,66.019c-8.9,2.25-11.278-.882-15.48-5.161,0,0,21.9-61.312,23.219-67.783s-3.88-47.27-3.059-54.779A141.262,141.262,0,0,1,30.688,123.5Z" fill="#1a4375" />
                    <path id="Path_45" data-name="Path 45" d="M61.42,33.16c-.34-.005,1.41,12.277,1.876,12.9-6.541.2-15.213,1.034-15.213,1.034a64.415,64.415,0,0,0,.057-21.551Z" fill="#fcb5a7" />
                    <g id="Group_28363" data-name="Group 28363" opacity="0.3" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                      <g id="Group_28362" data-name="Group 28362">
                        <g id="Group_28361" data-name="Group 28361" clipPath="url(#clip-path-3)">
                          <path id="Path_46" data-name="Path 46" d="M51.89,27.693a13.129,13.129,0,0,0,1.462,8.132c1.726,2.806,5.457,3.847,8.661,3.243a34.51,34.51,0,0,1-.593-5.908Z" fill="#fcb5a7" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_47" data-name="Path 47" d="M62.194,3.754a10.768,10.768,0,0,1,7.249,9.852c.365,6.054.7,12.051-2.876,17.259-1.566,2.284-2.885,3.489-4.332,4.022-2.411.888-6.177-.1-11.255-4.681-6.311-5.687-6.748-22.315,1.085-26.325,3.566-1.824,7.258-1.079,10.129-.127" fill="#fcb5a7" />
                    <path id="Path_48" data-name="Path 48" d="M61.889,41.427c1.374-.15,17.57,5.992,17.437,8.826-.066,1.424-2.243,24.052-1.665,44.413a215.448,215.448,0,0,1-1.4,33.41s-25.3,2.479-42.9-4.274c8.132-24.331,3.549-77.449,3.549-77.449a51.114,51.114,0,0,1,11.939-5.135Z" fill="#fcb5a7" />
                    <path id="Path_49" data-name="Path 49" d="M70.8,44.632C82.138,46.509,93.445,70.9,104.177,87.6c0,0-3.287,8.848-7.927,8.83C87.808,96.395,68.1,68.569,67.87,63.357c-.3-6.93-5.232-19.785,2.932-18.725" fill="#f77979" />
                    <g id="Group_28366" data-name="Group 28366" opacity="0.3" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                      <g id="Group_28365" data-name="Group 28365">
                        <g id="Group_28364" data-name="Group 28364" clipPath="url(#clip-path-4)">
                          <path id="Path_50" data-name="Path 50" d="M84.608,75.155c-2.943-3.946-3.625-8.515-4.457-13.247-.97-5.516-2.258-10.762-5.165-15.612A11.722,11.722,0,0,0,70.8,44.632c-8.164-1.059-3.234,11.795-2.932,18.725C68.1,68.57,87.808,96.395,96.25,96.428c4.64.018,7.927-8.83,7.927-8.83-.39-.606-.78-1.225-1.171-1.85-6.961-1.762-14.033-4.741-18.4-10.593" fill="#f77979" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_51" data-name="Path 51" d="M81.591,129.065s-39.263,11.347-53.946-.224c1.023-4.362,3.5-10.83,5.657-17.877,1.905-6.235,3.556-12.917,3.725-18.993.629-23.2-5.6-44.088-.581-46.88,5.16-2.863,12.487-3.9,12.487-3.9,2.458,5.683,12.468,5,12.468,5a3.564,3.564,0,0,0,1.191-4.72,32.162,32.162,0,0,1,11.184,4.559c.318.642,6.389,14.442,6.567,26.611A19.375,19.375,0,0,1,80.23,75c-.009.142-.028.286-.037.427a23.962,23.962,0,0,1-.451,2.695c-.027.142-.056.276-.084.418-.188.857-.4,1.687-.619,2.508A31.317,31.317,0,0,0,77.6,88.92c-.019,24.23,3.987,40.145,3.987,40.145" fill="#f77979" />
                    <g id="Group_28369" data-name="Group 28369" opacity="0.3" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                      <g id="Group_28368" data-name="Group 28368">
                        <g id="Group_28367" data-name="Group 28367" clipPath="url(#clip-path-5)">
                          <path id="Path_52" data-name="Path 52" d="M43.292,67.282c.355-7-3.086-12.774-6.78-18.355-.952,6.6.1,18.666.434,32.391.689-1.039,1.361-2.093,1.984-3.193,1.916-3.385,4.159-6.844,4.362-10.843" fill="#f77979" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28372" data-name="Group 28372" opacity="0.3" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                      <g id="Group_28371" data-name="Group 28371">
                        <g id="Group_28370" data-name="Group 28370" clipPath="url(#clip-path-6)">
                          <path id="Path_53" data-name="Path 53" d="M81.591,129.065s-39.263,11.347-53.946-.224c1.023-4.362,3.5-10.83,5.657-17.877a43.743,43.743,0,0,0,5.7.2c5.545-.062,11.6-.821,16.071-4.21,5.32-4.033,6.136-10.269,6.324-16.335.047-1.525-.254-3.229.159-4.728.6-2.149,3.2-2.65,5.207-2.979,3.312-.554,6.6-1.089,9.373-3.052a10.117,10.117,0,0,0,4.2-7.074A19.284,19.284,0,0,1,80.23,75c-.009.142-.028.286-.037.427-.113.928-.263,1.82-.451,2.695-.027.142-.056.276-.084.418-.188.857-.4,1.687-.619,2.508A31.317,31.317,0,0,0,77.6,88.92c-.019,24.23,3.987,40.145,3.987,40.145" fill="#f77979" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_54" data-name="Path 54" d="M48.989,55.324C49.411,65.543,30.343,80.831,17.8,94.278c0,0-8.925-.712-10.54-5.107-2.939-8,24.4-40.767,28.776-43.639,5.263-3.454,12.53,1.358,12.958,9.792" fill="#f77979" />
                    <path id="Path_55" data-name="Path 55" d="M51.89,14.962a17.1,17.1,0,0,0-2.222.554A2.85,2.85,0,0,0,48.513,17.9a25.974,25.974,0,0,0-.137,2.965,6.938,6.938,0,0,1-.3,2.781c-.362.866-1.108.553-2.015.306-1.755-2.477-1.815-5-1.876-8.033S43.86,8.487,45.44,5.895C50.229-1.964,60.283-.2,61.506,1.281s1.219,10.772-9.616,13.681" fill="#143556" />
                    <path id="Path_56" data-name="Path 56" d="M69.893,17.737c.477-1.862,5.811-7.371-.229-13C63.958-.58,57.788.722,57.326,1.771,53.9,9.533,59.568,7.88,60.1,9c2.228,4.69,8.345,3.106,9.792,8.734" fill="#143556" />
                    <path id="Path_57" data-name="Path 57" d="M48.422,21.271S47.19,16.843,43.95,17.9s.936,10.725,4.339,9.147c1.53-.71.133-5.776.133-5.776" fill="#fcb5a7" />
                    <g id="Group_28375" data-name="Group 28375" opacity="0.3" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                      <g id="Group_28374" data-name="Group 28374">
                        <g id="Group_28373" data-name="Group 28373" clipPath="url(#clip-path-7)">
                          <path id="Path_58" data-name="Path 58" d="M47.573,21.672s-.818-2.938-2.967-2.237.621,7.116,2.879,6.069c1.015-.471.088-3.832.088-3.832" fill="#fcb5a7" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_59" data-name="Path 59" d="M110.776,98.851A31.527,31.527,0,1,1,79.011,67.563a31.526,31.526,0,0,1,31.765,31.288" fill="#ffc933" />
                    <g id="Group_28378" data-name="Group 28378" opacity="0.2">
                      <g id="Group_28377" data-name="Group 28377">
                        <g id="Group_28376" data-name="Group 28376" clipPath="url(#clip-path-8)">
                          <path id="Path_60" data-name="Path 60" d="M110.731,97.323C108.562,81.91,95.4,69.085,79.411,69.207,62,69.339,47.854,84.582,47.986,101.994c0,.51.022,1.018.045,1.528a31.531,31.531,0,1,1,62.7-6.2" fill="#fff" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28381" data-name="Group 28381" opacity="0.5" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                      <g id="Group_28380" data-name="Group 28380">
                        <g id="Group_28379" data-name="Group 28379" clipPath="url(#clip-path-9)">
                          <path id="Path_61" data-name="Path 61" d="M110.776,98.851a31.527,31.527,0,0,1-62.949,2.8,31.528,31.528,0,0,0,62.751-4.613c-.006-.782-.041-1.552-.1-2.317a31.486,31.486,0,0,1,.3,4.135" fill="#ffc933" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28384" data-name="Group 28384" opacity="0.5" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                      <g id="Group_28383" data-name="Group 28383">
                        <g id="Group_28382" data-name="Group 28382" clipPath="url(#clip-path-10)">
                          <path id="Path_62" data-name="Path 62" d="M103.611,98.9A24.363,24.363,0,1,1,79.065,74.727,24.362,24.362,0,0,1,103.611,98.9" fill="#ffc933" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_63" data-name="Path 63" d="M103.613,98.906a24.367,24.367,0,0,1-46.772,9.759A24.371,24.371,0,0,0,100.8,87.7a24.249,24.249,0,0,1,2.816,11.2" fill="#ffc933" />
                    <g id="Group_28387" data-name="Group 28387" opacity="0.2">
                      <g id="Group_28386" data-name="Group 28386">
                        <g id="Group_28385" data-name="Group 28385" clipPath="url(#clip-path-11)">
                          <path id="Path_64" data-name="Path 64" d="M103.613,98.906a24.367,24.367,0,0,1-46.772,9.759A24.371,24.371,0,0,0,100.8,87.7a24.249,24.249,0,0,1,2.816,11.2" fill="#fff" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28390" data-name="Group 28390" opacity="0.5" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                      <g id="Group_28389" data-name="Group 28389">
                        <g id="Group_28388" data-name="Group 28388" clipPath="url(#clip-path-12)">
                          <path id="Path_65" data-name="Path 65" d="M102.223,90.965a24.368,24.368,0,0,0-45.329,17.819,24.367,24.367,0,1,1,45.329-17.819" fill="#ffc933" />
                        </g>
                      </g>
                    </g>
                    <g id="Group_28393" data-name="Group 28393" opacity="0.5" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}>
                      <g id="Group_28392" data-name="Group 28392">
                        <g id="Group_28391" data-name="Group 28391" clipPath="url(#clip-path-13)">
                          <text id="NFT" transform="translate(60.998 107.284) rotate(-0.434)" fill="#ffc933" fontSize="22.051" fontFamily="SegoeUI, Segoe UI"><tspan x={0} y={0}>NFT</tspan></text>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <text id="NFT-2" data-name="NFT" transform="translate(59.994 107.292) rotate(-0.434)" fill="#ffc933" fontSize="22.051" fontFamily="SegoeUI, Segoe UI"><tspan x={0} y={0}>NFT</tspan></text>
                <g id="Group_28400" data-name="Group 28400">
                  <g id="Group_28399" data-name="Group 28399" clipPath="url(#clip-path)">
                    <path id="Path_66" data-name="Path 66" d="M69.811,91.881A.258.258,0,0,1,70,91.8l3.13-.024a.256.256,0,0,1,.189.076.253.253,0,0,1,.078.186l.113,14.906a.261.261,0,0,1-.263.266l-3,.024a.316.316,0,0,1-.31-.174L64.8,98.679c-.03-.045-.059-.062-.088-.055s-.044.041-.043.1l.084,8.29a.25.25,0,0,1-.075.187.256.256,0,0,1-.188.079l-3.131.025a.26.26,0,0,1-.188-.077.257.257,0,0,1-.078-.187l-.113-14.9a.265.265,0,0,1,.262-.268l3.022-.022a.311.311,0,0,1,.309.175l5.114,8.362c.029.045.06.063.088.055s.045-.041.044-.1l-.085-8.268a.263.263,0,0,1,.076-.189" fill="#ffc933" />
                    <path id="Path_67" data-name="Path 67" d="M86.393,94.735a.258.258,0,0,1-.186.078l-6.9.052a.1.1,0,0,0-.11.111l.021,2.712a.1.1,0,0,0,.111.11l4.432-.035a.26.26,0,0,1,.188.077.256.256,0,0,1,.079.186l.019,2.581a.261.261,0,0,1-.263.265l-4.432.034a.1.1,0,0,0-.108.112l.044,5.887a.256.256,0,0,1-.076.188.252.252,0,0,1-.187.079l-3.131.023a.263.263,0,0,1-.267-.262l-.113-14.9a.265.265,0,0,1,.263-.268l10.408-.079a.261.261,0,0,1,.188.076.258.258,0,0,1,.079.187l.019,2.6a.26.26,0,0,1-.076.189" fill="#ffc933" />
                    <path id="Path_68" data-name="Path 68" d="M99.138,91.661a.26.26,0,0,1,.079.187l.019,2.624a.263.263,0,0,1-.262.266l-3.859.029a.1.1,0,0,0-.11.112l.091,11.906a.26.26,0,0,1-.076.189.256.256,0,0,1-.187.079l-3.132.023a.263.263,0,0,1-.265-.263l-.09-11.906a.1.1,0,0,0-.112-.111l-3.726.029a.245.245,0,0,1-.188-.076.251.251,0,0,1-.078-.187l-.021-2.624a.263.263,0,0,1,.263-.265l11.467-.087a.252.252,0,0,1,.187.075" fill="#ffc933" />
                    <g id="Group_28398" data-name="Group 28398" opacity="0.2">
                      <g id="Group_28397" data-name="Group 28397">
                        <g id="Group_28396" data-name="Group 28396" clipPath="url(#clip-path-15)">
                          <path id="Path_69" data-name="Path 69" d="M73.317,91.854a.257.257,0,0,0-.185-.072L70,91.806a.273.273,0,0,0-.188.074.277.277,0,0,0-.076.19l.086,8.13.481.786c.029.043.058.062.088.053s.043-.039.042-.1l-.086-8.27a.283.283,0,0,1,.075-.189.274.274,0,0,1,.189-.074l2.789-.021,0-.345a.259.259,0,0,0-.079-.188m-8.74.168a.319.319,0,0,0-.312-.172l-3.021.023a.256.256,0,0,0-.26.263l.113,14.906a.244.244,0,0,0,.08.187.237.237,0,0,0,.184.078l.344,0L61.6,92.738a.252.252,0,0,1,.076-.19.261.261,0,0,1,.184-.074l2.982-.023Z" fill="#fff" />
                          <path id="Path_70" data-name="Path 70" d="M86.368,91.754a.251.251,0,0,0-.185-.07l-10.408.079a.261.261,0,0,0-.188.074.274.274,0,0,0-.077.189l.113,14.9a.271.271,0,0,0,.078.189.254.254,0,0,0,.191.075h.339l-.11-14.566a.271.271,0,0,1,.077-.189.265.265,0,0,1,.188-.074l10.063-.077v-.344a.259.259,0,0,0-.08-.189" fill="#fff" />
                          <path id="Path_71" data-name="Path 71" d="M99.139,91.659a.272.272,0,0,0-.19-.072l-11.466.088a.266.266,0,0,0-.188.073.274.274,0,0,0-.076.19l.019,2.623a.266.266,0,0,0,.269.266l.34,0-.018-2.283a.279.279,0,0,1,.076-.191.269.269,0,0,1,.189-.074l11.126-.085,0-.345a.276.276,0,0,0-.079-.187" fill="#fff" />
                        </g>
                      </g>
                    </g>
                    <path id="Path_72" data-name="Path 72" d="M54.578,103.326c2.176.771,6.1,3.238,6.765,5.453-1.822-.44-5.139-2.295-6.995-2.453.53,1.781,6.59,2.861,6.459,4.91-.059.931-1.33,1.817-5.414,3.3-5.8,1.122-9.6-.926-15.536-7.368-.308-.334,2.4-5.692,3.071-5.464,3.648,1.244,9.474.85,11.65,1.623" fill="#fcb5a7" />
                    <path id="Path_73" data-name="Path 73" d="M44.064,111.908c-9.131-1.338-27.223-7.013-36.076-19.951C5.095,84.832,11,77.292,17.447,81.007c9.94,5.73,29.783,21.168,29.783,21.168-.71,2-2.476,7.726-3.166,9.733" fill="#f77979" />
                    <path id="Path_74" data-name="Path 74" d="M106.9,102.379a4.126,4.126,0,0,0-1.63,2.98,3.1,3.1,0,0,0,2.782,2.856,5.908,5.908,0,0,0,4.07-1.271,3.332,3.332,0,0,0,1.214-1.315,3.954,3.954,0,0,0,.237-1.117c.182-1.642-.228-5.376-1.315-6.617-2.407-2.753-2.873,2.461-5.358,4.484" fill="#fcb5a7" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </section>
  )
}

export default HowOur