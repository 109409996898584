import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className='bluecolor'>NFT Marketplace</span> Development Company - Coinsclone</h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-8 col-md-12 left-side" >
              <p className="pharagraph" >NFT Marketplace Development involves the process of building a digital platform for users in need of NFT trading, minting, listing, bidding, etc. The demand for NFT Marketplaces in the current crypto market is high and soaring. Level up your growth and get involved in a highly revenue-generating business idea in the form of NFT Marketplace development. You can become unstoppable by creating an NFT Marketplace that is bug-free and has extraordinary core functionalities.
              </p>
              <p className="pharagraph" >We, at Coinsclone are experts in building top-class and secure NFT Marketplaces. Our expert team helps you to build NFT Marketplaces in various blockchains including Ethereum, Polygon, etc. We excel in providing solutions to launch NFT Marketplaces with extensive and customizable features and plugins. In addition to these, we also offer advanced-level security protocols, an attractive UI/UX interface, and add-on modules.
              </p>
            </div>
            <div className="col-lg-4 col-md-12 right-side" >
              <img width="491px" height="266px" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-marketplace.png" alt="NFT Crypto Exchange" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis