import React from 'react'
import ButtonStartfree from '../ButtonComponent/ButtonStartfree'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">NFT Marketplace Development Company</h1>
            <p className="sub-heading mb-3">Gear up for the exponential journey in the crypto realm with upgraded NFT Marketplace Development Services offered by Coinsclone. Build an NFT Marketplace that reaps huge profits with an affordable investment.</p>
          </div>
          <div className="quick text-center">
            <img  width="983px" height="579px" src="https://coinsclone.mo.cloudinary.net/images/nft/nft-marketplace-development.png" alt="NFT Marketplace Development" />
          </div>
          <div className="text-center">
            <ButtonStartfree />
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection